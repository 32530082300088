.map-tool-panel-container {
    flex-wrap: wrap;
    width: 95px;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.473);
    top: 40px;
    right: -10px;
    z-index: 50;
    div {
        width: 40px;
        text-align: center;

        .active {
            background-color: #bf0707 !important;
            pointer-events: none;
        }

        .tool-button {
            background-color: #666666;
            width: 30px;
            border: 0;
            width: 30px;
            margin: 5px;
            padding-bottom: 4px;

            img {
                width: 18px;
                height: 18px;
            }
        }
    }
}
.map {
    width: 100%;
    height:400px;
  }
  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
  }
  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }
  .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
  }
  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }
  .ol-tooltip-static:before {
    border-top-color: #ffcc33;
  }