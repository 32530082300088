.no-data-for-display {text-align: center; margin-top: 20px;}
.reset-news-bg {background-color: #FFFFFF;}
.news-create-date {margin-bottom: 10px; font-size: 12px; color: #CCCCCC;}
.news-description {min-height: 20px; text-align: justify}
.news img {max-width: 98%;}
.news figure {text-align: center;}
.community-opinion {
  //border: 1px solid #f6f6f6;

  .header-doing {
    height: 2.5rem;
    background-color: #1075bd;

    .title {
      color: #ffffff;
      font-size: 1em;
      font-weight: 500;
    }

    .input-search {
      width: 14.5rem;
      padding-left: 10px;
      padding-right: 30px;
      height: 1.8rem;
      border: 1px solid #ffffff;
      border-radius: 2px;
      position: relative;
      font-size: 0.9em;
      outline: none;
    }

    .icon-search {
      position: absolute;
      right: 1.4rem;
      top: 0.7rem;
      font-size: 1.1rem;
      color: #888;
      cursor: pointer;
    }

    .btn {
      font-size: 0.9rem;
    }

    .dropdown-menu {
      border: unset;
      border-radius: 0;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    }
  }

  .content-doing {
    margin-top: 1rem;

    .first-item {
      img {
        height: 31.25rem;
        object-fit: cover;
      }

      .title {
        text-decoration: none;
        color: #000000;
        font-size: 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .item {
      margin-bottom: 2rem;

      img {
        height: 10rem;
        object-fit: cover;
      }

      .title {
        text-decoration: none;
        color: #000000;
        font-size: 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .header-result {
    height: 2.5rem;
    background-color: #1075bd;
    padding: 0.375rem;

    .title {
      padding-top: 0.2rem;

      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1em;
        font-weight: 500;
        padding-left: 1rem;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  .content-result {
    margin-top: 1rem;

    .item {
      margin-bottom: 1rem;
      padding-right: 0.5rem;

      img {
        height: 6.25rem;
        object-fit: cover;
      }

      a {
        text-decoration: none;
        font-size: 1em;
        font-weight: 600;
        color: #555;
      }
    }
  }

  .list-footer {
    border-top: 1px solid #b6b3b3;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .text-result {
      color: #c22e3c;
      font-weight: 600;
    }

    .pagination {
      border-radius: unset;

      .page-item {
        .page-link {
          border: 0;
          border-radius: 50%;
          color: #444444;
          padding: 6px 12px;
        }

        &.active {
          .page-link {
            background-color: #23d782;
            margin: 0 2px;
            color: #ffffff;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.news .list-hot-news {
  margin-top: 20px;
  margin-bottom: 30px;
}

.news .list-hot-news .new-item {
  margin-bottom: 10px;
}

.news .hot-new .detail-news-image img {
  margin-top: 0px;
  max-width: 100%;
}

figure {
  max-width: 100% !important;
}

.new-desc div img {
  max-width: 100% !important;
}

.new-content div img {
  max-width: 100% !important;
}


.news .list-hot-news .new-item:after {
  content: "";
  display: block;
  clear: both;
}

.news .list-hot-news .new-item:last-child {
  margin-bottom: 0;
}

.news .list-hot-news .new-item .new-image {
  width: 120px;
  height: auto;
  overflow: hidden;
  float: left;
}

.news .list-hot-news .new-item .new-image img {
  max-width: 100%;
}

.news .list-hot-news .new-item .new-info {
  margin-left: 130px;
  text-align: justify;
}

.news .list-hot-news .new-item .new-info .new-title {
  font-size: 16px;
  line-height: 19px;
  color: #333;
  display: block;
  margin-bottom: 6px;
}

.news .list-hot-news .new-item .new-info .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 28px;
}

.news .list-hot-news .new-item .new-info .view-detail {
  color: #3342B9;
  font-size: 16px;
  line-height: 19px;
}

.news .list-hot-news .new-item .new-info .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.news .list-hot-news .new-item .new-info .view-detail:hover {
  text-decoration: none;
}

@media (max-width: 575px) {
  .news .list-hot-news .new-item .new-image {
    width: 150px;
    height: 100px;
  }
  .news .list-hot-news .new-item .new-info {
    margin-left: 160px;
  }
  .news .list-hot-news .new-item .new-info .new-date {
    margin-bottom: 20px;
  }
}